@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.min-h-fill {
  min-height: calc(100vh - 16rem);
}

.h-38rem {
  height: 38rem;
}

body {
  margin: 0;
}

.max-h-80vh {
  max-height: 80vh;
}

.MuiDrawer-root > .MuiPaper-root {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  overflow: hidden;
}

.sketch-picker {
  box-shadow: none !important;
}
